/*
 * Usage
 * add data-controller='select_pill'
 * to the label
 * Actions
 * data-action="click->select_pill#toggle"
*/
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const input = this.element.querySelector  ('input')
    if (input.checked) {
      this.element.classList.add('checked')
    } else {
      this.element.classList.remove('checked')
    }
  }

  toggle(){
    this.element.classList.toggle('checked')
    this.element.parentNode.form.requestSubmit()
  }
}
