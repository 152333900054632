import { Controller } from '@hotwired/stimulus'
import { status, json, handleAPIResponse } from '@/shared/Fetch.mjs'
import { disableWith, revertDisableWith } from '@/shared/Buttons.mjs'

// Connects to data-controller="sync-task"
export default class extends Controller {
  syncPath = ''

  connect() {
    this.syncPath = this.element.dataset.fetchPath
  }

  doSync(event) {
    var csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    const syncResult = this.createSyncResultElement({
      message: `<loading-bar data-message="Syncing..."></loading-bar>`,
      html: true,
      title: event.target.dataset.title
    })
    disableWith(event.target)
    syncResult.classList.toggle('active')
    fetch(this.syncPath, {
      method: 'get',
      credentials: 'include',
      headers: { 'X-CSRF-Token': csrfToken }
    })
      .then(status)
      .then(json)
      .then(handleAPIResponse)
      .then((response) => {
        if (response.success()) {
          this.updateSyncResult(syncResult, {
            message: 'Data synced'
          })
          syncResult.classList.toggle('complete')
        } else {
          this.updateSyncResult(syncResult, {
            message: `Error: ${response.error}`,
            html: false,
            error: JSON.stringify(response.errors)
          })
        }
        revertDisableWith(this.element)
      })
      .catch((error) => {
        console.error(error.message)
        this.updateSyncResult(syncResult, { message: `Error: ${error}` })
        revertDisableWith(this.element)
        syncResult.classList.toggle('failed')
      })
      .finally(() => {
        syncResult.classList.toggle('active')
        syncResult.querySelector('loading-bar').style.display = 'none'
      })
  }

  createTemplate() {}

  addSyncResult() {
    const timestamp = document.querySelector('.sync-timestamp')
    timestamp.textContent = `Started: ${new Date().toLocaleTimeString()}`
  }

  updateSyncResult(element, options) {
    const message = document.createElement('code')
    if (options['html']) {
      message.innerHTML = options['html']
    } else {
      message.textContent = `Message: ${options['message']}`
    }
    element.append(message)
    if (options['error']) {
      const errorBlock = document.createElement('code')
      errorBlock.textContent = options['error']
      errorBlock.classList.add('sync-error')
      element.append(errorBlock)
    }
  }

  createSyncResultElement(options) {
    const syncSection = document.getElementById('sync-result-container')
    const syncResultsGroup = syncSection.querySelector('#sync_results_group')
    const syncResult = document.createElement('div')

    const syncTitleBar = document.createElement('div')
    syncTitleBar.classList.add('flex', 'flex-row', 'flex-end')
    syncResult.append(syncTitleBar)

    if (options['title']) {
      const syncTitle = document.createElement('h3')
      syncTitle.textContent = options['title']
      syncTitle.classList.add('grow')
      syncTitleBar.appendChild(syncTitle)
    }
    const dismiss = document.createElement('span')
    dismiss.innerHTML = '&times;'
    dismiss.classList.add('close-button')
    dismiss.addEventListener('click', (event) => {
      event.target.parentElement.parentElement.remove(event.target.parentNode)
    })
    syncTitleBar.append(dismiss)

    const timestamp = document.createElement('code')
    timestamp.textContent = `Started: ${new Date().toLocaleTimeString()}`
    timestamp.classList.add('sync-timestamp')
    syncResult.appendChild(timestamp)
    // if (options['html']) {
    //   const message = document.createElement('code')
    //   message.innerHTML = options['html']
    //   syncResult.appendChild(message)
    // } else {
    //   const message = document.createElement('code')
    //   message.textContent = options['message']
    //   syncResult.appendChild(message)
    // }
    // if (options['error'] === true) {
    //   const errorBlock = document.createElement('code')
    //   code.textContent = options['error']
    //   code.classList.add('sync-error')
    //   syncResult.appendChild(errorBlock)
    // }
    syncResult.classList.add('sync-result')
    const loadingBar = document.createElement('loading-bar')
    syncResult.append(loadingBar)
    syncResultsGroup.prepend(syncResult)
    return syncResult
  }
}
