import { Controller } from '@hotwired/stimulus'
import Tablesort from 'tablesort'
window.Tablesort = Tablesort
import('tablesort/src/sorts/tablesort.number.js')
import('tablesort/src/sorts/tablesort.date.js')
import('tablesort/src/sorts/tablesort.dotsep.js')
import('tablesort/src/sorts/tablesort.filesize.js')
import('tablesort/src/sorts/tablesort.monthname.js')

// Connects to data-controller="sortable"
export default class extends Controller {
  sortable

  static values = {
    setup: Boolean
  }

  connect() {
    const direction = this.element.dataset.tableSortable ?? 'asc'
    this.element.addEventListener(
      'mousedown',
      () => {
        if (!this.setupValue) {
          new Tablesort(this.element, direction)
          this.setupValue = true
        } else {
          console.log('already setup')
        }
      },
      true
    )
    // do the work...
    // this.element.querySelectorAll('th').forEach(th => th.addEventListener('click', (() => {
    //   const table = th.closest('table');
    //   Array.from(table.querySelectorAll('tr:nth-child(n+2)'))
    //       .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
    //       .forEach(tr => table.appendChild(tr) );
    //   })
    // ))
  }

  getCellValue = (tr, idx) =>
    tr.children[idx].innerText || tr.children[idx].textContent

  comparer = (idx, asc) => (a, b) =>
    ((v1, v2) =>
      v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2)
        ? v1 - v2
        : v1.toString().localeCompare(v2))(
      getCellValue(asc ? a : b, idx),
      getCellValue(asc ? b : a, idx)
    )
}
