// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css

// require.context('../images', true) // or this to import all the images at once
import '../controllers/application' // Stimulus

import '@hotwired/turbo-rails'
// import * as ActiveStorage from "@rails/activestorage";
// Turbo.session.drive = false

// import { registerSW } from 'virtual:pwa-register'
// registerSW({})
// ActiveStorage.start()
import '../custom-elements/loading-bar.js'
import { registerSW } from 'virtual:pwa-register'

const updateSW = registerSW({
  onOfflineReady() {}
})

function updateSidebar() {
  const toggle = localStorage.getItem('sidebar-toggle')
  if (toggle && toggle === 'hidden') {
    document.querySelector('#wrapper')?.classList.add('shrink-nav')
    document.querySelector('#shrink-arrow')?.classList.remove('fa-angles-left')
    document.querySelector('#shrink-arrow')?.classList.add('fa-angles-right')
  } else {
    document.querySelector('#wrapper')?.classList.remove('shrink-nav')
    document.querySelector('#shrink-arrow')?.classList.remove('fa-angles-right')
    document.querySelector('#shrink-arrow')?.classList.add('fa-angles-left')
  }
}

// const loadEvent = typeof Turbo == "object" ? "turbo:load" : "DOMContentLoaded"
document.addEventListener('turbo:load', () => {
  // let flashClose = document.querySelector("#flash .close")
  // flashClose && flashClose.addEventListener('click', (event) => {
  //   event.target.parentNode.addEventListener('animationend', () => {
  //     console.log('animationend')
  //     event.target.parentNode.parentNode.remove(event.target.parentNode)
  //   }, 1000)
  //   event.target.parentNode.classList.add('dismiss-flash')
  // })

  const arrowCollapse = document.querySelector('#shrink-arrow')
  if (arrowCollapse) {
    arrowCollapse.addEventListener('click', (e) => {
      document.querySelector('#wrapper')?.classList.toggle('shrink-nav')
      document
        .querySelector('#shrink-arrow')
        ?.classList.toggle('fa-angles-left')
      document
        .querySelector('#shrink-arrow')
        ?.classList.toggle('fa-angles-right')
      //     const sidebarVisibility = localStorage.getItem('sidebar-toggle') ? localStorage.getItem('sidebar-toggle') : 'visible'
      //     if (sidebarVisibility === 'visible') {
      //       localStorage.setItem('sidebar-toggle', 'hidden')
      //     } else {
      //       localStorage.setItem('sidebar-toggle', 'visible')
      //     }
      // updateSidebar()
    })
    document.querySelectorAll('#sidebar a').forEach((link) => {
      link.addEventListener('click', (e) => {
        e.stopPropagation()
      })
    })
    updateSidebar()
  }
})

Turbo.StreamActions.animated_remove = async function () {
  this.targetElements.forEach(async (target) => {
    target.animate(
      [
        {
          transform: `scale(1)`,
          transformOrigin: 'top',
          height: 'auto',
          opacity: 1.0
        },
        {
          transform: `scale(0.8)`,
          opacity: 0.2,
          height: '80%',
          offset: 0.8
        },
        {
          transform: `scale(0)`,
          transformOrigin: 'top',
          height: 0,
          opacity: 0
        }
      ],
      {
        duration: 400,
        easing: 'ease-out'
      }
    )
    await Promise.all(
      target.getAnimations().map((animation) => animation.finished)
    )
    target.remove()
  })
}
Turbo.StreamActions.animated_prepend = async function () {
  this.removeDuplicateTargetChildren()
  this.targetElements.forEach(async (target) => {
    target.prepend(this.templateElement.content)
    target.firstElementChild.animate(
      [
        {
          transform: `scaleY(0.0)`,
          transformOrigin: 'top',
          height: '0',
          opacity: 0.0
        },
        {
          transform: `scale(0.8)`,
          opacity: 0.2,
          height: '80%',
          offset: 0.2
        },
        {
          transform: `scaleY(1)`,
          transformOrigin: 'top',
          height: 'auto',
          opacity: 1
        }
      ],
      {
        duration: 400,
        easing: 'ease-out'
      }
    )
  })
}

Turbo.StreamActions.animated_append = async function () {
  this.removeDuplicateTargetChildren()
  this.targetElements.forEach(async (target) => {
    target.append(this.templateElement.content)
    target.lastElementChild.animate(
      [
        {
          transform: `scaleY(0.0)`,
          transformOrigin: 'top',
          height: '0',
          opacity: 0.0
        },
        {
          transform: `scale(0.8)`,
          opacity: 0.2,
          height: '80%',
          offset: 0.2
        },
        {
          transform: `scaleY(1)`,
          transformOrigin: 'top',
          height: 'auto',
          opacity: 1
        }
      ],
      {
        duration: 400,
        easing: 'ease-out'
      }
    )
  })
}
