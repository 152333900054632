class LoadingBar extends HTMLElement {
  constructor() {
    // Always call super first in constructor
    super();

    // Create a shadow root
    var shadow = this.attachShadow({mode: 'open'});

    // Create (nested) span elements
    const wrapper = document.createElement('span');
    wrapper.setAttribute('class','wrapper');
    const icon = wrapper.appendChild(document.createElement('span'));
    icon.setAttribute('class','icon');
    icon.setAttribute('tabindex', 0);
    // Insert icon from defined attribute or default icon
    const img = icon.appendChild(document.createElement('img'));
    img.src = '/images/spinner.gif';
    img.width = 30
    img.height = 30
    img.style.verticalAlign = "middle"

    const message = wrapper.appendChild(document.createElement('span'));
    message.setAttribute('class','message');
    // Take attribute content and put it inside the info span
    message.textContent = this.getAttribute('data-message');

    // Create some CSS to apply to the shadow dom
    const style = document.createElement('style');
    style.textContent = '.wrapper {}' +
    // CSS truncated for brevity

    // attach the created elements to the shadow DOM
    shadow.appendChild(style)
    shadow.appendChild(wrapper)
    wrapper.appendChild(icon)
    wrapper.appendChild(message)
  }
}
function addCustomElement(){
  customElements.define("loading-bar",  LoadingBar)  
}
// add call here, because onload did not work for me
addCustomElement()


