import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['close']
  connect() {}

  dismissFlash() {
    this.element.addEventListener(
      'animationend',
      () => {
        console.log('animationend')
        this.element.parentNode.remove(this.element)
      },
      1000
    )
    this.element.classList.add('dismiss-flash')
  }
}
