/*
 * Usage
 * add data-controller='modal
 *
 * Actions
 * data-action="click->modal#open"
 * data-title="My Modal Title"
 */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['title', 'header', 'content', 'dialog', 'footer']
  // static values = {
  //   title: String
  // }

  connect() {
    document.addEventListener('turbo:submit-end', this.handleSubmit.bind(this))
    document.addEventListener(
      'modal-opener:showModal',
      this.openTurboModal.bind(this)
    )
    document.addEventListener(
      'modal-opener:closeModal',
      this.clickClose.bind(this)
    )
  }

  disconnect() {
    document.removeEventListener(
      'turbo:submit-end',
      this.handleSubmit.bind(this)
    )
    document.removeEventListener(
      'modal-opener:closeModal',
      this.clickClose.bind(this)
    )
  }

  openTurboModal(event) {
    if (this.dialogTarget.open) {
      return
    }
    // event.preventDefault();
    // const modalTitle = this.element.querySelector('form')?.dataset?.title
    if (event.detail.params.title) {
      this.titleTarget.textContent = event.detail.params.title
    }
    if (event.detail.params.type) {
      this.headerTarget.classList.add(`modal-title-${event.detail.params.type}`)
    }

    this.dialogTarget.showModal()
    // if (!this.element.classList.contains('show-modal')) {
    //   this.element.classList.add('show-modal')
    // }
  }

  closeTurboModal(event) {
    console.log('opening modal dialog')
    if (event.detail.success) {
      this.element.classList.remove('show-modal')
      this.element.remove()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      // Turbo.visit(event.detail.fetchResponse.response.url)
      // this.close()
    }
  }

  // hideBeforeRender(event) {
  //   event.preventDefault()
  //   this.element.parentNode.addEventListener('turbo-frames:', event.detail.resume)
  //   this.element.remove()
  // }

  clickClose(event) {
    event.preventDefault()
    // Don't know what this is for
    // this.element.parentNode.addEventListener(
    //   'turbo-frames:',
    //   event.detail.resume
    // )
    this.dialogTarget.close()
    // this.element.classList.remove('show-modal')
  }

  closeWithKeyboard(event) {
    event.preventDefault()
    if (event.code === 'Escape') {
      this.dialogTarget.close()
      // this.element.classList.remove('show-modal')
      // transferableAbortSignal.
    }
  }
}
