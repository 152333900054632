/*
 * Usage
 * add data-controller='navmenu'
 * to the label
 * Actions
 * data-action="click->select_pill#toggle"
 */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['wrapper']

  connect() {
    // this.setupSidebar()
    if (localStorage.getItem('sidebar:visible') === 'true') {
      // const sidebarVisible = localStorage.getItem('sidebar:visible')
      // const menu = document.querySelector('#wrapper')
      // if (sidebarVisible === 'true') {
      // menu.classList.remove('shrink-nav')
      // } else {
      // menu.classList.add('shrink-nav')
      // }
      this.expand()
    } else {
      this.collapse()
    }
  }

  toggleMenu() {
    const menu = document.querySelector('#wrapper')
    if (menu.classList.contains('shrink-nav')) {
      localStorage.setItem('sidebar:visible', 'true')
    } else {
      localStorage.setItem('sidebar:visible', 'false')
    }
    this.setupSidebar()
    const blocker = document.querySelector('#blocker')
    if (blocker !== null) {
      // blocker.style.display = menu.classList.contains('shrink-nav') ? 'block' : 'none'
    }
  }

  setupSidebar() {
    if (localStorage.getItem('sidebar:visible')) {
      const sidebarVisible = localStorage.getItem('sidebar:visible')
      const menu = document.querySelector('#wrapper')
      if (sidebarVisible === 'true') {
        menu.classList.remove('shrink-nav')
      } else {
        menu.classList.add('shrink-nav')
      }
    }
  }

  expand() {
    // this.wrapperTarget.
    // document.querySelector('#wrapper').classList.add('hidden')
    localStorage.setItem('sidebar:visible', 'true')
  }

  collapse() {
    // this.wrapperTarget.
    // document.querySelector('#wrapper').classList.remove('hidden') // Not sure why this was added - tailwind uses this class
    localStorage.setItem('sidebar:visible', 'false')
  }

  smoothExpand() {
    // this.wrapperTarget
    document
      .querySelector('#wrapper')
      .classList.add('ease-in-out', 'duration-300')
  }

  smoothCollapse() {
    // this.wrapperTarget.
    document
      .querySelector('#wrapper')
      .classList.remove('ease-in-out', 'duration-300')
  }
}
