import { Application } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
const application = Application.start()
registerControllers(application, controllers)

// Configure Stimulus development experience
// application.warnings = true
application.debug = false
window.Stimulus = application
// export { application }
