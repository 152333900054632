import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  openModal(event) {
    this.dispatch('showModal', {
      detail: {
        params: event.params
      }
    })
  }

  clickClose() {
    this.dispatch('closeModal')
  }
}
