import { Controller } from "@hotwired/stimulus"
import * as Alerts from '@/shared/Alerts.mjs'
import { status, json, handleAPIResponse } from '@/shared/Fetch.mjs'

export default class extends Controller {
  static targets = []
  static values = {
    systemId: String
  }

  connect() {
  }

  sync() {
    const startLabel = this.element.textContent
    this.element.textContent = this.element.dataset.disableWith
    this.element.disabled = true
    this.element.classList.add('button-disabled')
    var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch(`/api/v1/sync/system?id=${this.systemIdValue}`, {
      method: 'post',
      headers: { 'X-CSRF-Token': csrfToken},
    })
    .then(status)
    .then(json)
    .then(handleAPIResponse)
    .then((response) => {
      if (response.success()) {
        Alerts.create({
          message: "Sync Complete. Refreshing page...",
          type: 'notice',
          title: 'Portal Sync',
          timeout: 5000,
          callback: () => {
            // window.location.reload()
            Turbo.visit(window.location)
          }
        })
      } else {
        Alerts.create({
          message: `${response.errors.length} sync tasks failed. ${response.data.length} sync operations complete.`,
          type: response.errors.length > 0 ? 'warning' : 'notice',
          title: 'Portal Sync',
          timeout: 5000
        })
        if (response.errors.length > 0) {
          console.error("Tasks errors:", response.errors)
        }
      }
    })
    .catch(error =>{
      Alerts.setToast("Sync Error. Check logs for details", 'danger', 'Sync wih Portal')
      console.error(error)
    })
    .finally(() => {
      this.element.disabled = false
      this.element.textContent = startLabel
      this.element.classList.remove('button-disabled')
    })
  }
}
